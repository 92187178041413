$width: 820px;
$primary: #ef4444;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Open+Sans:wght@400;500&display=swap");
* {
}
.text-pri {
  color: $primary;
}

body{
  overflow: hidden;
}

.bg-pri {
  background-color: $primary;
}
.App {
 
  height: 100vh;
  width: 100%;
  padding-top: 2rem;
}
.footer{
  max-width: $width;
  width: 100%;
  margin: 0 auto;
}
.page {
  max-width: $width;
  width: 100%;
  margin: 0 auto;
  margin-top: 0.7rem;
  background-color: white;
  font-family: "Open Sans", sans-serif;
}
.header {
  font-family: "Montserrat", sans-serif;
  ul {
    li {
      margin-right: 14px;
      .active {
        &::after {
          background-color: $primary;

          transform: scale(1) !important;
          opacity: 1 !important;
          
         
        }
        position: relative;
        color: $primary !important;
      }
      a {
        font-weight: 500;
        color: #64748b;

        &::after{
          transition: .2s;
          opacity: 0;
          width: 100%;
          transform: scale(0);
          height: 3px;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          position: absolute;
          content: "";
          left: 0;
          bottom: -23px;
        }
        &:hover {
          color: #000;
        }

        &:active {
          color: $primary;
        }
      }
    }
  }
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  background-color: white;
  margin: 0 auto;
  max-width: $width;

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .logo-title {
      color: $primary;
    }
  }
}

.e-btn {
  background-color: $primary;
  border-radius: 0.25rem;
  font-weight: 500;
  color: white;
  padding: 3px 8px;
  transition: 0.2s;
  &:hover {
    opacity: 0.8;
  }

  &:disabled{
    opacity: 0.8;
  }
}

.e-i {
  background-color: #f1f5f9;
  border-radius: 0.25rem;
  padding: 0.5rem;
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: #e2e8f0;
  }

  &:focus {
    outline-color: $primary;
  }
}

.musteri-dropdown {
  width: 100%;
  background-color: #f9fafb;
  border-radius: 0.25rem;
  margin-top: 5px;
  padding: 0.3rem;
  border: 1px solid $primary;
  z-index: 10000;
}

.musteri-search {
  input {
    background-color: transparent;
    margin-left: 6px;
    outline: none;
  }

  select {
    border-radius: 100px;
    border: 1px solid $primary;
    color: $primary;
    font-weight: bold;
    outline: none;
  }
}

.in {
  animation: in 0.2s;
}

.tab-active {
  font-weight: 500;
  user-select: none !important;
  transition: 0.2s;

  color: $primary;
  border-bottom: 2px solid $primary;
}

.tab-item {
  color: #94a3b8;
  font-weight: 500;
  transition: 0.2s;
  user-select: none !important;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  &:hover {
    color: #334155;
    background-color: #f9fafb;
    border-bottom: 2px solid #94a3b8;
  }
}

@keyframes in {
  from {
    opacity: 0;
    transform: scale(0.8) translateY(20px);
  }
}

.ay-select {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-auto-rows: 1fr 1fr;
  grid-gap: 2px;
  .ay-active {
    transition: 0.5s;
    &::before {
      content: "";
      transform: translate(-50%, 0) scale(1) !important;
    }
    color: white !important;
  }
  .ay {
    z-index: 100;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none !important;
    border-radius: 100%;
    aspect-ratio: 1/1;
    font-weight: 500;
    cursor: pointer;
    transition: 0.5s;
    background-color: rgba(#f8fafc, 0.32);
    color: #475569;

    &::before {
      content: "";
      background-color: $primary !important;

      transition: 0.2s;
      transform: translate(-50%, 0) scale(0);
      z-index: -1;
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 100%;
      left: 50%;
    }
    &:hover {
      color: #000;
      background-color: rgba(#f1f5f9, 1);
    }
  }
}

.kalan-sms {
  background-color: $primary;
}

.grup-active {
  background-color: $primary !important;
  color: white;
}

.input-dogrula {
  position: relative;

  &::after {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.input-dogrula-hata {
  &::after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f071";
    color: #dc2626;
  }
}

.input-dogrula-ok {
  &::after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f00c";
    color: #22c55e;
  }
}

.e-r {
  border-radius: 0.25rem;
  padding: 9px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;

  text-align: center;
  user-select: none !important;
  cursor: pointer;
}


.show-big-grup{
  position: absolute;
  z-index: 1000;
  width: 100%;
  background-color: #f1f5f9;
  border-radius: .25rem;
  max-height: 0;
  padding: 0;
  overflow: hidden;
  transition:  .2s;
}

.big-grup-parent{
  cursor: pointer;

  &:hover{
    .show-big-grup{
      padding: 8px;

      max-height:300px;
    }
  }
}

thead{
  tr{
    border-top-left-radius: 8px;
    background-color: $primary !important; 
    th{
      color: white !important;
    }
  }
  tr td{
 
    color: white !important;
   
  }
}

.max-table{
  max-height: 300px;
  overflow: auto;
  

  tr{
    &:nth-child(odd){
      background-color: #f8fafc;
    }
    &:nth-child(even){
      background-color: #f3f4f6;
    }
  }

}